/* eslint-disable react/jsx-props-no-spreading */
import { EntityModel } from "hateoas-hal-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { useDeviceSelectors } from "react-device-detect";
import { FaBuffer, FaFilter, FaProductHunt, FaSearch } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { HelpLink, HELP_LINK_SEARCH_TAB } from "../../../components/HelpLink/help-link";
import SearchFieldController from "../../../components/SearchFieldController/search-field-controller";
import { useSearchContext } from "../../../contexts/search.context";
import DocumentStatus from "../../../models/DocumentStatus.enum";
import RefData from "../../../models/RefData.model";
import { SearchHit } from "../../../models/SearchHit.model";
import SummaryTopic from "../../../models/SummaryTopic.model";
import {
  DocumentStatusBadge,
  fetchSuggestions,
  prefetchDocumentStats,
} from "../../../services/document-services";
import { useActsProceedings, useActYears } from "../../../services/ref-data-services";
import { useSummaryTopics } from "../../../services/summary-topic.services";
import { useAvailableFilters, useQuery, useSearchCategory } from "../search-utils";
import SavedSearches from "./saved-searches";
import "./search-input.scss";

const SearchInput = () => {
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  return isMobile ? <SearchInputMobile /> : <SearchInputNonMobile />;
};

const SearchInputNonMobile = () => {
  const navigate = useNavigate();

  const searchCategory = useSearchCategory();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SearchHit[]>([]);

  const availableFilters = useAvailableFilters("acts");
  const proceedings = useActsProceedings();
  const actYears = useActYears();
  const summaryTopics = useSummaryTopics();

  const query = useQuery();

  const handleSearch = useCallback(
    async (query: string) => {
      setIsLoading(true);

      fetchSuggestions(query).then((result) => {
        setOptions((current) => [
          ...current.filter((o) => o.category === "filter" || o.category === "query"),
          ...result,
        ]);
      });

      setOptions([
        {
          id: "query",
          category: "query",
          status: DocumentStatus.PUBLIC,
          text: "query",
        },
        ...suggestReporters(availableFilters, query),
        ...suggestProceeding(availableFilters, proceedings?.data || [], query),
        ...suggestCaseNumber(query),
        ...suggestActYear(availableFilters, actYears?.data || [], query),
        ...suggestActPeriod(query),
        ...suggestSummaryTopic(availableFilters, summaryTopics?.data || [], query),
        ...suggestProvision(availableFilters, query),
      ]);

      setIsLoading(false);
    },
    [proceedings.data, actYears?.data, availableFilters, summaryTopics?.data]
  );

  const handleSuggestion = async (ref: any, newValue: any, value: any, onChange: any) => {
    ref.hideMenu();
    const isOnLandingPage = (searchCategory as string) === "search";
    const { navigate } = await onChange(
      {
        query: [newValue],
        sort: "relevance,desc",
      },
      false
    );
    navigate(isOnLandingPage ? "/search/acts" : pathname);
  };

  const handleFilter = async (ref: any, filterName: any, newValue: any, onChange: any) => {
    ref.hideMenu();
    const isOnLandingPage = (searchCategory as string) === "search";
    const { navigate } = await onChange(
      {
        [filterName]: [...(query[filterName] || []), newValue],
      },
      false
    );
    navigate(isOnLandingPage ? "/search/acts" : pathname);
  };

  const [ref, setRef] = useState<any>();
  useEffect(() => {
    if (ref) {
      ref.clear();
    }
  }, [query, ref]);

  const { pathname } = useLocation();
  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const { opened } = useSearchContext();

  useEffect(() => {
    if (ref && !isMobile && opened.length === 0) {
      setTimeout(() => {
        ref.focus({
          preventScroll: true,
        });
      }, 100);
    }
  }, [pathname, ref, isMobile, opened]);

  const onRefChange = useCallback((node: any) => {
    // ref value changed to node
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);

  const { formatMessage } = useIntl();

  const highlighted = useRef<SearchHit | null>(null);

  return (
    <SearchFieldController
      name="query"
      preload={prefetchDocumentStats}
      render={({ field: { onChange, value } }) => (
        <InputGroup className="mb-0 search-input">
          <Button
            style={{ paddingRight: "0" }}
            variant="light"
            onClick={async () => {
              if (!ref.state.text) {
                return;
              }
              const isOnLandingPage = (searchCategory as string) === "search";
              const { navigate } = await onChange(
                {
                  query: [...value, ref.state.text],
                  sort: "relevance,desc",
                },
                false
              );
              navigate(isOnLandingPage ? "/search/acts" : pathname);
            }}
          >
            <FaSearch style={{ rotate: "90deg" }} />
            <HelpLink articleId={HELP_LINK_SEARCH_TAB} />
          </Button>
          <AsyncTypeahead
            ref={onRefChange}
            filterBy={() => true}
            id="search-input"
            isLoading={isLoading}
            labelKey="text"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder="Търсене по ключови думи, теми, разпоредби като напр. чл. 32 ЗС"
            promptText={formatMessage({ id: "search-input.enter-search-word" })}
            emptyLabel={formatMessage({ id: "search-input.search-in-progress" })}
            searchText={formatMessage({ id: "search-input.search-in-progress" })}
            useCache={false}
            autoFocus={false}
            defaultInputValue={value[0] || ""}
            defaultSelected={undefined}
            onKeyDown={async (event: any) => {
              if (event.key === "Enter") {
                const option = highlighted.current;
                if (option) {
                  if (option.category === "keyword") {
                    handleSuggestion(ref, option.text, value, onChange);
                  } else if (option.category === "filter") {
                    const [filterName, filterValue] = option.id.split(":");
                    handleFilter(ref, filterName, filterValue, onChange);
                  } else {
                    navigate(`/${option.category}/${option.id}`);
                  }
                } else if (event.target.value) {
                  ref.hideMenu();
                  const isOnLandingPage = (searchCategory as string) === "search";
                  const { navigate } = await onChange(
                    {
                      query: [...value, event.target.value],
                      sort: "relevance,desc",
                    },
                    false
                  );
                  navigate(isOnLandingPage ? "/search/acts" : pathname);
                }
              }
            }}
            renderMenu={(results, menuProps, state) => {
              const filters = results.filter((option) => option.category === "filter");
              const keywords = results.filter((option) => option.category === "keyword");
              const documents = results.filter(
                (option) =>
                  option.category !== "filter" &&
                  option.category !== "keyword" &&
                  option.category !== "query"
              );
              let index = 0;
              console.log(results);
              return (
                <Menu {...menuProps}>
                  {!state.text && (
                    <Menu.Header>
                      {formatMessage({ id: "search-input.enter-search-word" })}
                    </Menu.Header>
                  )}
                  {state.text && state.text.length >= 3 && results.length > 0 && (
                    <>
                      <MenuItem option={results[0]} position={0} key={state.text}>
                        <div
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const isOnLandingPage = (searchCategory as string) === "search";
                            const { navigate } = await onChange(
                              {
                                query: [...value, state.text],
                                sort: "relevance,desc",
                              },
                              false
                            );
                            navigate(isOnLandingPage ? "/search/acts" : pathname);
                          }}
                        >
                          <span className="float-start">
                            <FaSearch style={{ rotate: "90deg" }} />
                          </span>
                          Търсене по фраза "{state.text}"
                        </div>
                      </MenuItem>

                      {filters.length !== 0 && (
                        <>
                          <Menu.Header>ФИЛТРИ</Menu.Header>
                          {filters.map((option) => {
                            index = index + 1;
                            return (
                              <MenuItem key={option.id} option={option} position={index}>
                                <div
                                  title={option.text}
                                  onClick={() => {
                                    const [filterName, filterValue] = option.id.split(":");
                                    handleFilter(ref, filterName, filterValue, onChange);
                                  }}
                                >
                                  <span className="float-start">
                                    <FaFilter />
                                  </span>
                                  {option.text}
                                  {!!option.count && (
                                    <span
                                      className="text-muted fst-italic"
                                      style={{ fontSize: "0.85rem" }}
                                    >
                                      {" "}
                                      ({option.count} резултата)
                                    </span>
                                  )}
                                </div>
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                      {keywords.length !== 0 && (
                        <>
                          <Menu.Header>КЛЮЧОВИ ИЗРАЗИ</Menu.Header>
                          {keywords.map((option, index) => {
                            index = index + 1;
                            return (
                              <MenuItem key={option.id} option={option} position={index}>
                                <div
                                  title={option.text}
                                  onClick={() => {
                                    handleSuggestion(ref, option.text, value, onChange);
                                  }}
                                >
                                  <span className="float-start">
                                    <FaSearch style={{ rotate: "90deg" }} />
                                  </span>
                                  {option.text}
                                </div>
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                      {documents.length !== 0 && (
                        <>
                          <Menu.Header>ДОКУМЕНТИ</Menu.Header>
                          {documents.map((option, index) => {
                            index = index + 1;
                            return (
                              <MenuItem key={option.id} option={option} position={index}>
                                <div
                                  title={option.text}
                                  onClick={() => {
                                    navigate(`/${option.category}/${option.id}`);
                                  }}
                                >
                                  {option.id.indexOf("#") > 0 && (
                                    <span className="float-start">
                                      <FaProductHunt />
                                    </span>
                                  )}
                                  {option.category !== "keyword" &&
                                    option.category !== "filter" &&
                                    option.id.indexOf("#") < 1 && (
                                      <span className="float-start">
                                        <FaBuffer />
                                      </span>
                                    )}
                                  {option.text}
                                  {option.id.indexOf("#") < 1 && (
                                    <span className="float-end">
                                      <DocumentStatusBadge status={option.status} />
                                    </span>
                                  )}
                                </div>
                              </MenuItem>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </Menu>
              );
            }}
          >
            {(state) => {
              highlighted.current =
                state.activeInde !== -1 ? state.results[state.activeIndex] : null;
            }}
          </AsyncTypeahead>
          <SavedSearches />
        </InputGroup>
      )}
    />
  );
};

const SearchInputMobile = () => {
  const navigate = useNavigate();

  const searchCategory = useSearchCategory();
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SearchHit[]>([]);

  const availableFilters = useAvailableFilters("acts");
  const proceedings = useActsProceedings();
  const actYears = useActYears();
  const summaryTopics = useSummaryTopics();
  const query = useQuery();

  const handleSearch = useCallback(
    async (query: string) => {
      setIsLoading(true);

      fetchSuggestions(query).then((result) => {
        setOptions((current) => [
          ...current.filter((o) => o.category === "filter" || o.category === "query"),
          ...result,
        ]);
      });

      setOptions([
        {
          id: "query",
          category: "query",
          status: DocumentStatus.PUBLIC,
          text: "query",
        },
        ...suggestReporters(availableFilters, query),
        ...suggestProceeding(availableFilters, proceedings?.data || [], query),
        ...suggestCaseNumber(query),
        ...suggestActYear(availableFilters, actYears?.data || [], query),
        ...suggestActPeriod(query),
        ...suggestSummaryTopic(availableFilters, summaryTopics?.data || [], query),
        ...suggestProvision(availableFilters, query),
      ]);

      setIsLoading(false);
    },
    [proceedings.data, actYears?.data, availableFilters, summaryTopics?.data]
  );

  const handleSuggestion = async (ref: any, newValue: any, value: any, onChange: any) => {
    ref.hideMenu();
    const isOnLandingPage = (searchCategory as string) === "search";
    const { navigate } = await onChange(
      {
        query: [newValue],
        sort: "relevance,desc",
      },
      false
    );
    navigate(isOnLandingPage ? "/search/acts" : pathname);
  };

  const handleFilter = async (ref: any, filterName: any, newValue: any, onChange: any) => {
    ref.hideMenu();
    const isOnLandingPage = (searchCategory as string) === "search";
    const { navigate } = await onChange(
      {
        [filterName]: [...(query[filterName] || []), newValue],
      },
      false
    );
    navigate(isOnLandingPage ? "/search/acts" : pathname);
  };

  const { formatMessage } = useIntl();

  const [ref, setRef] = useState<any>();
  useEffect(() => {
    if (ref) {
      setTimeout(() => {
        ref.focus();
      }, 100);
    }
  }, [ref]);

  const onRefChange = useCallback((node: any) => {
    // ref value changed to node
    setRef(node); // e.g. change ref state to trigger re-render
  }, []);

  const [showInput, setShowInput] = useState(false);
  return (
    <>
      <SearchFieldController
        name="query"
        preload={prefetchDocumentStats}
        render={({ field: { onChange, value } }) => (
          <InputGroup className="mb-0 search-input">
            <Button variant="light" onClick={() => {}}>
              <FaSearch style={{ rotate: "90deg" }} />
            </Button>
            <AsyncTypeahead
              onFocus={(e) => {
                e.preventDefault();
                setShowInput(true);
              }}
              isLoading={false}
              onSearch={() => {}}
              options={[]}
              placeholder="Търсене по ключови думи"
            />
            <SavedSearches />
          </InputGroup>
        )}
      />
      <Modal
        id="search-input-modal"
        show={showInput}
        placement="bottom"
        fullscreen
        centered
        restoreFocus={false}
        animation={false}
      >
        <Modal.Body>
          <SearchFieldController
            name="query"
            preload={prefetchDocumentStats}
            render={({ field: { onChange, value } }) => (
              <InputGroup className="mb-0 search-input">
                <Button variant="light" onClick={() => setShowInput(false)}>
                  <FiArrowLeft />
                </Button>
                <AsyncTypeahead
                  ref={onRefChange}
                  filterBy={() => true}
                  isLoading={isLoading}
                  labelKey="text"
                  minLength={3}
                  onSearch={handleSearch}
                  options={options}
                  promptText={formatMessage({ id: "search-input.enter-search-word" })}
                  emptyLabel=""
                  searchText=""
                  open
                  onKeyDown={async (event: any) => {
                    if (event.key === "Enter" && event.target.value) {
                      const isOnLandingPage = (searchCategory as string) === "search";
                      const { navigate } = await onChange(
                        {
                          query: [...value, event.target.value],
                          sort: "relevance,desc",
                        },
                        false
                      );
                      navigate(isOnLandingPage ? "/search/acts" : pathname);
                      setShowInput(false);
                    }
                  }}
                  useCache={false}
                  autoFocus={true}
                  defaultInputValue=""
                  defaultSelected={undefined}
                  renderMenu={(results, menuProps, state) => {
                    const filters = results.filter((option) => option.category === "filter");
                    const keywords = results.filter((option) => option.category === "keyword");
                    const documents = results.filter(
                      (option) =>
                        option.category !== "filter" &&
                        option.category !== "keyword" &&
                        option.category !== "query"
                    );
                    let index = 0;
                    console.log(results);
                    return (
                      <Menu {...menuProps}>
                        {!state.text && (
                          <Menu.Header>
                            {formatMessage({ id: "search-input.enter-search-word" })}
                          </Menu.Header>
                        )}
                        {state.text && state.text.length >= 3 && results.length > 0 && (
                          <>
                            <MenuItem option={results[0]} position={0} key={state.text}>
                              <div
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const isOnLandingPage = (searchCategory as string) === "search";
                                  const { navigate } = await onChange(
                                    {
                                      query: [...value, state.text],
                                      sort: "relevance,desc",
                                    },
                                    false
                                  );
                                  navigate(isOnLandingPage ? "/search/acts" : pathname);
                                }}
                              >
                                <span className="float-start">
                                  <FaSearch style={{ rotate: "90deg" }} />
                                </span>
                                Търсене по фраза "{state.text}"
                              </div>
                            </MenuItem>

                            {filters.length !== 0 && (
                              <>
                                <Menu.Header>ФИЛТРИ</Menu.Header>
                                {filters.map((option) => {
                                  index = index + 1;
                                  return (
                                    <MenuItem key={option.id} option={option} position={index}>
                                      <div
                                        title={option.text}
                                        onClick={() => {
                                          const [filterName, filterValue] = option.id.split(":");
                                          handleFilter(ref, filterName, filterValue, onChange);
                                        }}
                                      >
                                        <span className="float-start">
                                          <FaFilter />
                                        </span>
                                        {option.text}
                                        {!!option.count && (
                                          <span
                                            className="text-muted fst-italic"
                                            style={{ fontSize: "0.85rem" }}
                                          >
                                            {" "}
                                            ({option.count} резултата)
                                          </span>
                                        )}
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </>
                            )}
                            {keywords.length !== 0 && (
                              <>
                                <Menu.Header>КЛЮЧОВИ ИЗРАЗИ</Menu.Header>
                                {keywords.map((option, index) => {
                                  index = index + 1;
                                  return (
                                    <MenuItem key={option.id} option={option} position={index}>
                                      <div
                                        title={option.text}
                                        onClick={() => {
                                          handleSuggestion(ref, option.text, value, onChange);
                                        }}
                                      >
                                        <span className="float-start">
                                          <FaSearch style={{ rotate: "90deg" }} />
                                        </span>
                                        {option.text}
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </>
                            )}
                            {documents.length !== 0 && (
                              <>
                                <Menu.Header>ДОКУМЕНТИ</Menu.Header>
                                {documents.map((option, index) => {
                                  index = index + 1;
                                  return (
                                    <MenuItem key={option.id} option={option} position={index}>
                                      <div
                                        title={option.text}
                                        onClick={() => {
                                          navigate(`/${option.category}/${option.id}`);
                                        }}
                                      >
                                        {option.id.indexOf("#") > 0 && (
                                          <span className="float-start">
                                            <FaProductHunt />
                                          </span>
                                        )}
                                        {option.category !== "keyword" &&
                                          option.category !== "filter" &&
                                          option.id.indexOf("#") < 1 && (
                                            <span className="float-start">
                                              <FaBuffer />
                                            </span>
                                          )}
                                        {option.text}
                                        {option.id.indexOf("#") < 1 && (
                                          <span className="float-end">
                                            <DocumentStatusBadge status={option.status} />
                                          </span>
                                        )}
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </Menu>
                    );
                  }}
                />
              </InputGroup>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const suggestReporters = (availableFilters: any, query: string) => {
  return (
    Object.keys(availableFilters?.reporter || {})
      .filter((reporter) => reporter.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1)
      .sort((v1, v2) => v1.localeCompare(v2))
      .splice(0, 1)
      .map((reporter) => ({
        category: "filter",
        id: `reporter:${reporter}`,
        text: `Докладчик: ${reporter}`,
        count: availableFilters?.reporter[reporter],
        status: DocumentStatus.PUBLIC,
      })) || []
  );
};
const suggestProceeding = (
  availableFilters: any,
  proceedings: EntityModel<RefData>[],
  query: string
) => {
  return (
    proceedings
      .filter(
        (proceeding) => proceeding.name.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1
      )
      .filter((proceeding) => availableFilters?.proceeding?.[proceeding.id])
      .sort((v1, v2) => v1.name.localeCompare(v2.name))
      .splice(0, 1)
      .map((proceeding) => ({
        category: "filter",
        id: `proceeding:${proceeding.id}`,
        text: `Вид производство: ${proceeding.name}`,
        count: availableFilters?.proceeding?.[proceeding.id],
        status: DocumentStatus.PUBLIC,
      })) || []
  );
};

const suggestCaseNumber = (query: string) => {
  return query.match(/^\d+\w?\/\d{4}$/)
    ? [
        {
          category: "filter",
          id: `caseReference:${query}`,
          text: `№ дело/година: ${query}`,
          status: DocumentStatus.PUBLIC,
        },
      ]
    : [];
};

const suggestActYear = (availableFilters: any, actYears: RefData[], query: string) => {
  return (
    actYears
      .filter((actYear) => actYear.name.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1)
      .filter((actYear) => availableFilters?.actYear?.[actYear.id])
      .sort((v1, v2) => v1.name.localeCompare(v2.name))
      .splice(0, 1)
      .map((actYear) => ({
        category: "filter",
        id: `actYear:${actYear.id}`,
        text: `Година на акта: ${actYear.name}`,
        count: availableFilters?.actYear?.[actYear.id],
        status: DocumentStatus.PUBLIC,
      })) || []
  );
};

const suggestActPeriod = (query: string) => {
  return query.match(/^\d{1,2}\.\d{2}\.\d{4}$/)
    ? [
        {
          category: "filter",
          id: `actPeriod:${query}-${query}`,
          text: `Дата на акта: ${query}`,
          status: DocumentStatus.PUBLIC,
        },
      ]
    : query.match(/^\d{1,2}\.\d{2}\.\d{4}\s*-\s*\d{1,2}\.\d{2}\.\d{4}$/)
    ? [
        {
          category: "filter",
          id: `actPeriod:${query.replaceAll(/\s*/, "")}`,
          text: `Дата на акта: ${query.replaceAll(/\s*-\s*/, " - ")}`,
          status: DocumentStatus.PUBLIC,
        },
      ]
    : [];
};

const suggestSummaryTopic = (
  availableFilters: any,
  summaryTopics: SummaryTopic[],
  query: string
) => {
  return (
    summaryTopics
      .filter((topic) => topic.name.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1)
      .filter((topic) => availableFilters?.summaryTopic?.[topic.id])
      .sort((v1, v2) => v1.name.localeCompare(v2.name))
      .splice(0, 5)
      .map((topic) => ({
        category: "filter",
        id: `summaryTopic:${topic.id}`,
        text: `Тема: ${topic.name}`,
        status: DocumentStatus.PUBLIC,
        count: availableFilters?.summaryTopic?.[topic.id],
      })) || []
  );
};

const suggestProvision = (availableFilters: any, query: string) => {
  return (
    Object.keys(availableFilters?.relatedProvision || {})
      .map((provision) => ({
        title: provision,
        count: availableFilters?.relatedProvision[provision],
        index: provision.toLowerCase().indexOf(query.toLocaleLowerCase()),
      }))
      .filter((provision) => provision.index !== -1)
      .sort((v1, v2) => v1.index - v2.index || v1.title.localeCompare(v2.title))
      .splice(0, 5)
      .map((provision) => ({
        category: "filter",
        id: `relatedProvision:${provision.title}`,
        text: `Тълкувана разпоредба: ${provision.title}`,
        count: provision.count,
        status: DocumentStatus.PUBLIC,
      })) || []
  );
};

export default SearchInput;
